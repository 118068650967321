.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(9rem, 3fr));
    grid-auto-rows: 1fr;
    margin: auto;
    width: 80%;
  }

.grid div{
    padding: 10px;
}
  
  .grid::before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  
  .grid > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  
  /* Just to make the grid visible */
  
  .grid > * {
    /* background: rgba(0,0,0,0.1); */
    /* border: 1px white solid; */
  }

  .csd{
    background-image: "../../assets/images/csd.jpeg";
  }